/* fonts.css */

@font-face {
  font-family: "SDSamliphopangcheBasic";
  src: url("../font/SDSamliphopangcheBasic.otf") format("opentype");
  /* 폰트 파일의 경로와 포맷을 지정합니다. */
  font-weight: lighter;
  font-style: normal;
}

:root {
  --font-size-lg: 5vw;
  --font-size-md: 4vw;
  --font-size-sm: 3vw;
}

h1 {
  font-size: var(--font-size-lg);
}

h2 {
  font-size: var(--font-size-md);
}

p {
  font-size: var(--font-size-sm);
}

a {
  font-size: var(--font-size-sm);
}

blockquote {
  font-size: var(--font-size-md);
}

.hero {
  font-size: var(--font-size-lg);
}
