.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  height: 60px; /* Adjust this value to your footer height */
}

.main-content {
  margin-bottom: 60px; /* Same value as the footer height */
}
